<template>
    <div class="container content-field" v-if="!$store.state.user.pulling_info">
        <div class="card">
            <div class="card-body">
                <div class="padd row justify-content-md-center">
                    <div class="col-12">
                        <form @submit.prevent="login">
                            <div class="mb-3">
                                <label for="username" class="form-label">用户名：</label>
                                <input v-model="username" type="text" class="form-control" id="username"
                                    placeholder="请输入用户名/电话号码/邮箱">
                            </div>
                            <div class="mb-3">
                                <label for="password" class="form-label">密码：</label>
                                <input v-model="password" type="password" class="form-control" id="password"
                                    placeholder="请输入密码">
                            </div>
                            <div class="message">{{ message }}</div> <br>
                            <button type="submit" class="btn btn-primary">登 录</button>
                            <div class="mb-3 form-group reg">
                                <router-link class="nav-link" :to="{ name: 'user_account_register' }">
                                    没有账号？立即注册
                                </router-link>
                            </div>
                            <div class="others">
                                <span class="line"></span>
                                <span class="text">其他方式登录</span>
                                <span class="line"></span>
                            </div>
                            <div @click="qq_login" style="text-align: center; margin-top: 20px; cursor: pointer;">
                                <img height="30"
                                    src="https://wiki.connect.qq.com/wp-content/uploads/2013/10/03_qq_symbol-1-250x300.png" />
                                <div class="text">QQ</div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref } from 'vue'
import router from '../../../router/index'
import $ from 'jquery'

export default {
    setup() {
        const store = useStore();
        let username = ref('');
        let password = ref('');
        let message = ref('');

        const jwt_token = localStorage.getItem("jwt_token");
        if (jwt_token) {
            store.commit("updateToken", jwt_token);
            store.dispatch("getinfo", {
                success() {
                    router.push({ name: "home" });
                    store.commit("updatePullingInfo", false);
                },
                error() {
                    store.commit("updatePullingInfo", false);
                }
            })
        } else {
            store.commit("updatePullingInfo", false);
        }

        const login = () => {
            message.value = "";
            store.dispatch("login", {
                username: username.value,
                password: password.value,
                success() {
                    store.dispatch("getinfo", {
                        success() {
                            router.push({ name: 'home' });
                        }
                    })
                },
                error() {
                    message.value = "用户名或密码错误";
                }
            })
        }

        const qq_login = () => {
            $.ajax({
                url: "https://www.czytqg.com/api/user/account/qq/apply_code/",
                type: "GET",
                success: resp => {
                    if (resp.result === "success") {
                        window.location.replace(resp.apply_code_url);
                    }
                }
            })
        }

        return {
            username,
            password,
            message,
            login,
            qq_login
        }
    }
}
</script>

<style scoped>
div.content-field {
    margin-top: 50px;
    max-width: 500px;
}

.padd {
    padding: 24px;
}

.reg {
    display: block;
    font-style: italic;
    margin: 20px 0;
    font-size: 1rem;
    text-decoration: none;
    color: #007bff;
    transition: color 0.3s ease-in-out;
}

.reg:hover {
    text-decoration: underline;
}

.others {
    display: flex;
    align-items: center;
    color: dimgray;
    text-align: center;
    margin-top: 20px;
}

.line {
    flex-grow: 1;
    height: 1px;
    background-color: gray;
}

.text {
    margin: 0 10px;
}

button {
    width: 100%;
    height: 40px;
}

div.message {
    color: red;
}
</style>